<template>
  <div class="auth-page">
    <div class="container page">
      <div class="row">
        <div class="col-md-6 offset-md-3 col-xs-12 box-container">
          <div class="text-center">
            <img src="../assets/logo.png" style="max-width: 100%; margin-bottom: 20px;"/>
          </div>
          <div class="white-box">
            <h1 class="text-xs-center">Sign in</h1>

            <ul v-if="errors" class="error-messages">
              <li :key="i"
                v-for="(v, i) in errors">
                {{ v }}
              </li>
            </ul>

            <form v-on:submit.prevent="onSubmit(email, password)">
              <fieldset class="form-group">
                <input
                  class="form-control form-control-lg"
                  type="text"
                  v-model="email"
                  placeholder="Email">
              </fieldset>
              <fieldset class="form-group">
                <input
                  class="form-control form-control-lg"
                  type="password"
                  v-model="password"
                  placeholder="Password">
              </fieldset>
              <button class="btn btn-lg btn-primary pull-right">
                Sign in
              </button>
            </form>
          </div>
          <div class="text-center footer-text">
            TrustedSource | Qmica (c) 2021 - <a href="https://www.trustedsource.co.za">https://www.trustedsource.co.za</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import axios from 'axios'
import store from '@/store'
import { Config } from '../../config/index.js'
import '../assets/scss/login-register.css'

export default {
  name: 'RwvLogin',
  data () {
    return {
      email: null,
      password: null,
      errors: []
    }
  },
  methods: {
    onSubmit (email, password) {
      axios
        .post(Config.APP_VALIDATION_API + '/auth/login',
          {
            email: email,
            password: password
          })
        .then(response => {
          if (response.data.error) {
            alert(response.data.payload)
          } else {
            console.log(store)
            store.state.auth = response.data.payload
            this.$router.push({ name: 'Home' })
          }
        })
    }
  },
  computed: {
  },
  created: function () {
    if (store.state.auth !== null) {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>
